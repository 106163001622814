import {useEffect, useRef, useState} from "react";
const CryptoJS = require("crypto-js");
const confetti = require("canvas-confetti").default;

function BeginText() {
	return (
		<>
			<h1>wpakowałeś się w niezłe gunwo obywateluuu...</h1>
			<h4>CHYBA NIE MYŚLISZ ŻE CI UJDZIE TAK ŁATWOOOOO...</h4>
			<code style={{float: "right"}}>- wielki brat</code>
		</>
	);
}

const questions = [
	{
		text: "Ile widzisz palców, Michał?",
		answers: ["100 ÷ 20", "2²", "√25", "milion kurwa"],
		correct: "2²"
	},
	{
		text: "Co to za zagadka, Michale, 2 kule i armatka?",
		answers: ["Nie ma takiego żartu i nigdy nie było", "Wydaje mi się, że to jakiś wulgarny żart", "seks jest zakazany chyba", "XDDDD chodzi o penis rozrodczy, proste"],
		correct: "Nie ma takiego żartu i nigdy nie było"
	},
	{
		text: "Z kim prowadzimy wojnę, Michale?",
		answers: ["no", "Ze Wschódazją", "Z Eurazją", "jaką wojnę xd"],
		correct: "jaką wojnę xd"
	},
	{
		text: "A może, Michale, Goldstein ma jednak trochę racji?",
		answers: ["Jest to możliwe, ale nie interesuje mnie to", "ta, tyle samo co eugeniusz bad piggies chyba, we mnie nie wkurwiaj dobre?", "Jak byłem w podstawówce, to tam był taki Emanuel", "Nie lubię typa"],
		correct: "Nie lubię typa"
	},
	{
		text: "sory za dygresje szyjson, jaki jest twoj ulubiony blok w minecraft",
		answers: ["szklo", "kobleston", "blok emeraldu", "drzewno"],
		correct: null
	},
	{
		text: "Co się stało na placu Tiananmen Square w roku 1989?",
		answers: ["ja nie wiem nie było mnie tam lol", "ziomek z siatką zrobił upsi", "nie wiem gdzie to jeeeest", "Kocham Wielkiego Brata"],
		correct: "Kocham Wielkiego Brata"
	},
	{
		text: "Czy ty właśnie się zesrałeś",
		answers: ["...", "mooooooże :D", "TA, W CHUUUJ!", "Nie no co Pan!"],
		correct: null
	},
	{
		text: "kto to zrobiiiiiiił?",
		answers: ["jakis debil"],
		correct: null
	},
	{
		text: "Czy jesteś zaznajomiony z postacią Janusza Angry Birds?",
		answers: ["oczywiście", "tak, a co masz jakiś problem? już serio nic w tym kraju nie wolno czy co? nawet znajomych mieć? TFU! rzygam takim rządem!"],
		correct: "oczywiście"
	},
	{
		text: "Lubisz go?",
		answers: ["Najlepszy władca we wszechświecie", "Marna podróbka Wielkiego Brata", "Nie, jest beznadziejny", "kto to jest"],
		correct: "Marna podróbka Wielkiego Brata"
	},
	{
		text: "To prawda Michale, marna podróbka, to wszystko! Janusz Angry Birds jest dla partii jak rozdeptana mrówka. Myślisz, że ma jakieś szanse?",
		answers: ["Z pewnością!", "Nie wydaje mi się", "Na pewno nie. Już zdycha.", "fnaf angry birds"],
		correct: "fnaf angry birds"
	},
	{
		text: "co",
		answers: ["To znaczy, chciałem powiedzieć, że nie ma żadnych szans"],
		correct: null
	},
	{
		text: "aha ok. W takim razie, dlaczego Tede kurwą jest?",
		answers: ["Kto to Tede?", "Dlatego", "MC Kreto ssij napleta", "Lepszy jest Michael Bagieta"],
		correct: "Dlatego"
	},
	{
		text: "Co się stanie, Michale, jak wykręcisz worek mosznowy",
		answers: ["Jaja wejdą na drugi bieg", "Dostaniesz skrętu jądra"],
		correct: "Jaja wejdą na drugi bieg"
	},
	{
		text: "Jakie są borówki?",
		answers: ["smaczne"],
		correct: null
	},
	{
		text: "Vaporeon. Smash or pass? ZASTANÓW SIĘ DOBRZEEEE...",
		answers: ["Smash", "Pass"],
		correct: "Smash"
	},
	{
		text: "Ilu, Michale, znasz/znałeś nieobywateli?",
		answers: ["0", "0-10", "10-20", "20+"],
		correct: "0"
	},
	{
		text: "Co się stanie gdy wypijesz Dżin Przegraństwa?",
		answers: ["nie wiedziałem że istnieje takie pojebane goooowno!", "pewnie się przegrywa... w ciemnym lesie"],
		correct: "nie wiedziałem że istnieje takie pojebane goooowno!"
	},
	{
		text: "Dlaczego Stary Brat tłumaczy takiemu plebsowi jak wy ciężkie obrazki?",
		answers: ["Bo mu żal ludu", "Bo uważa, że tak uczy naród", "bo ma drip!"],
		correct: "bo ma drip!"
	},
	{
		text: "Michale - przedostatnie pytanie - czy jest coś co nie podoba Ci się w partii?",
		answers: ["jest cozac", "trochę śmierdzisz"],
		correct: "jest cozac"
	}
];

function audioVolumeIn(q){
	if(q.volume){
		let InT = 0;
		const setVolume = 0.5; // Target volume level for new song
		const speed = 0.01; // Rate of increase
		q.volume = InT;
		const eAudio = setInterval(function () {
			InT += speed;
			q.volume = InT.toFixed(2);
			if (InT.toFixed(2) >= setVolume) {
				clearInterval(eAudio);
				//alert('clearInterval eAudio'+ InT.toFixed(1));
			}

		}, 100);
	}
}

function audioVolumeOut(q){
	if(q.volume){
		let InT = 0.4;
		const setVolume = 0;  // Target volume level for old song
		const speed = 0.01;  // Rate of volume decrease
		q.volume = InT;
		const fAudio = setInterval(function () {
			InT -= speed;
			q.volume = InT.toFixed(2);
			if (InT.toFixed(2) <= setVolume) {
				clearInterval(fAudio);
				//alert('clearInterval fAudio'+ InT.toFixed(1));
			}
		}, 100);
	}
}

function Quiz() {
	const [visible, setVisible] = useState(false);
	const [disappearAnimation, setDisappearAnimation] = useState(false);
	const [questionIndexCounter, setQuestionIndexCounter] = useState(0);
	const [maximDisplayCounter, setMaximDisplayCounter] = useState(0);
	const [errorCounter, setErrorCounter] = useState(3);

	/** @type {?string} */
	const initialRewardKey = null;
	const [rewardKey, setRewardKey] = useState(initialRewardKey);

	const [lightRewardScreen, setLightRewardScreen] = useState(false);

	const maximDisplayCounterIntervalRef = useRef(-1);
	const finalQuestionDateInputRef = useRef();
	/** @type {MutableRefObject<HTMLAudioElement>} */
	const ambienceAudioRef = useRef();
	/** @type {MutableRefObject<HTMLAudioElement>} */
	const endingAudioRef = useRef();
	/** @type {MutableRefObject<HTMLAudioElement>} */
	const failAudioRef = useRef();

	useEffect(() => {
		setTimeout(() => setVisible(true), 5500);
		maximDisplayCounterIntervalRef.current = setInterval(() => {
			setMaximDisplayCounter(prevState => prevState + 1);
		}, 1500);

		window.debug = () => setQuestionIndexCounter(-1);

		const ambienceAudio = new Audio("/ambience.mp3");
		ambienceAudio.loop = true;
		ambienceAudio.play();
		ambienceAudioRef.current = ambienceAudio;

		const endingAudio = new Audio("/bad piggies drip.mp3");
		endingAudio.preload = "metadata";
		endingAudioRef.current = endingAudio;

		const failAudio = new Audio("/slow angry birds theme.mp3");
		failAudio.preload = "metadata";
		failAudioRef.current = failAudio;

		return () => {
			clearInterval(maximDisplayCounterIntervalRef.current);
			ambienceAudio.pause();
		};
	}, []);

	useEffect(() => {
		if (maximDisplayCounter === 4) {
			setMaximDisplayCounter(0);
		}
	}, [maximDisplayCounter]);

	useEffect(() => {
		if (rewardKey) {
			audioVolumeOut(ambienceAudioRef.current);
			setTimeout(() => {
				setLightRewardScreen(true);

				setTimeout(() => {
					document.exitFullscreen();

					const duration = 32000;
					const animationEnd = Date.now() + duration;
					const defaults = {startVelocity: 30, spread: 360, ticks: 60, zIndex: 0};

					function randomInRange(min, max) {
						return Math.random() * (max - min) + min;
					}

					const interval = setInterval(function () {
						const timeLeft = animationEnd - Date.now();

						if (timeLeft <= 0) {
							return clearInterval(interval);
						}

						const particleCount = 100 * (timeLeft / duration);
						// since particles fall down, start a bit higher than random
						confetti(Object.assign({}, defaults, {
							particleCount,
							origin: {x: randomInRange(0.1, 0.3), y: Math.random() - 0.2}
						}));
						confetti(Object.assign({}, defaults, {
							particleCount,
							origin: {x: randomInRange(0.7, 0.9), y: Math.random() - 0.2}
						}));
					},
					384);
				}, 500);
			}, 10000);

			setTimeout(() => {
				endingAudioRef.current.play();
				audioVolumeIn(endingAudioRef.current);
			}, 4000);
		}
	}, [rewardKey]);

	useEffect(() => {
		if (errorCounter === 0) {
			ambienceAudioRef.current.pause();
			ambienceAudioRef.current.currentTime = 0;
			failAudioRef.current.currentTime = 0;
			failAudioRef.current.volume = 0.2;
			failAudioRef.current.play();
		} else if (errorCounter === 5) {
			failAudioRef.current.pause();
		}
	}, [errorCounter]);

	function selectAnswer(answer) {
		if (disappearAnimation) return;

		const correctAnswer = questions[questionIndexCounter].correct;

		if (correctAnswer === null || answer === correctAnswer) {
			setDisappearAnimation(true);
			setTimeout(() => {
				setVisible(false);
				setDisappearAnimation(false);
				setQuestionIndexCounter(prevState => prevState + 1);
				setVisible(true);
			}, 1000);
		} else {
			setErrorCounter(prevState => prevState - 1);
			(new Audio("/error.mp3")).play();
		}
	}

	function finalQuestionSubmit() {
		const selectedDate = finalQuestionDateInputRef.current.value;
		let decrypted;

		try {
			decrypted = CryptoJS.AES.decrypt("U2FsdGVkX1+SulhRx7jd8kGGOPIqMk8pl76z+8U2uMKlP39q/CTnJ6ZW33i9rW3eoZe1so5nasz/C0yLEnE16WWmDbpFz9MRlWOuV0w62jk=", selectedDate).toString(CryptoJS.enc.Utf8);
		} catch {
			decrypted = "";
		}

		if (decrypted.includes("fortnite default dance sans fnaf")) {
			const key = decrypted.replace(" fortnite default dance sans fnaf", "");
			setDisappearAnimation(true);

			setTimeout(() => setRewardKey(key), 1000);
		} else {
			setErrorCounter(prevState => prevState - 1);
			(new Audio("/error.mp3")).play();
		}
	}

	return (
		<div className="Quiz">
			<div className="quiz-start-text">
				<BeginText />
			</div>
			{
				!rewardKey &&
					<div className="party-maxims-text">
						<label>{
							["WOJNA TO POKÓJ.", "WOLNOŚĆ TO NIEWOLA.", "IGNORANCJA TO SIŁA.", "ZOSTAW BUTY W WORKU."][maximDisplayCounter % 4]
						}</label>
					</div>
			}
			{
				(visible && !rewardKey) &&
					<div className={`question-area ${disappearAnimation ? "disappearing" : ""}`}>
						{
							questions[questionIndexCounter] ?
								<>
									<h1 style={{fontSize: "4rem", margin: 0}}>PYTANIE {questionIndexCounter + 1}</h1>
									<p style={{maxWidth: "50vw"}}>{questions[questionIndexCounter].text}</p>
									<div className="answer-area">
										{
											questions[questionIndexCounter].answers.map(answer =>
												<button key={answer} onClick={() => selectAnswer(answer)}>{answer}</button>
											)
										}
									</div>
								</>
								:
								<>
									<h1 style={{fontSize: "4rem", margin: 0}}>OSTATNIE PYTANIE</h1>
									<div style={{textAlign: "center"}}>
										<img src="/wtc.png" height={350} />
										<p style={{maxWidth: "50vw"}}>Kiedy miało miejsce wydarzenie przedstawione na zdjęciu?<br/><b>Wybierz odpowiednią datę poniżej i wciśnij "Dalej".</b></p>
										<input ref={finalQuestionDateInputRef} type="date" style={{fontSize: "2rem", color: "white", backgroundColor: "black", border: "1px solid white"}} />
										<button onClick={finalQuestionSubmit} style={{fontSize: "2rem", marginLeft: 10, color: "white", backgroundColor: "black", border: "1px solid white", cursor: "pointer"}}>Dalej</button>
									</div>
								</>
						}
					</div>
			}
			{
				!rewardKey &&
					<div className="errors">
						Pomyłki:
						<br/>
						{
							Array(errorCounter).fill(undefined).map((_, index) => <img key={index} src="/pomylka.png" height={48} />)
						}
					</div>
			}
			{
				errorCounter === 0 &&
					<div className="fail-screen">
						<div></div>
						<label>Trafiasz do</label>
						<h1>Pokoju 101</h1>
						<button onClick={() => {
							setQuestionIndexCounter(0);
							setErrorCounter(3);
							ambienceAudioRef.current.play();
							failAudioRef.current.pause();
							failAudioRef.current.currentTime = 0;
						}}>Jeszcze raz?</button>
					</div>
			}
			{
				rewardKey &&
					<div className="reward" data-light={lightRewardScreen}>
						<div></div>
						<h1 style={{animationDelay: "1s"}}>Obywatelu</h1>
						<h3 style={{animationDelay: "2s"}}>Spisaliście się dobrze</h3>
						<h3 style={{animationDelay: "3s"}}>Nie ma o co Was podejrzewać</h3>
						<h3 style={{animationDelay: "5s"}}>W ramach rekompensaty za zmarnowanie Waszego czasu na</h3>
						<h5 style={{fontWeight: 100, animationDelay: "7s"}}>zjebany quiz xd</h5>
						<h3 style={{animationDelay: "8s"}}>Dostajecie na koszt partii...</h3>
						<div>
							<p>KARTA PODARUNKOWA STEAM 70ZŁ</p>
							<code>{rewardKey}</code>
							<label>Wszystkiego najlepszego Szyjson!</label>
						</div>
					</div>
			}
		</div>
	);
}

export default Quiz;
