import './App.css';
import {useEffect, useState} from "react";
import Quiz from "./Quiz";

function App() {
	const [started, setStarted] = useState(false);
	const [secondsUntilPolice, setSecondsUntilPolice] = useState(63);

	useEffect(() => {
		setInterval(() => {
			setSecondsUntilPolice(prevState => prevState - 1);
		}, 1000);
	}, []);

	return started ? <Quiz /> : (
		<>
			<img src="gora.png" height={180} style={{position: "absolute", top: 0, left: "50vw", transform: "translateX(-50%)"}} onClick={() => {
				alert("kto ci pozwolił tu klikać? śmieszne to jest dla ciebie? jak ja ci pokażę coś smiesznego zaraz...");
				window.open("/mem.jpg", "_top");
			}} />
			<div className="start-screen">
				<h1 style={{color: "red", marginTop: 0}}>OBYWATELU!</h1>
				<h4>Wygląda na to, że znajdujesz się na liście gończej.</h4>
				<p>
					Nie odchodź od komputera i poczekaj na dotarcie służb specjalnych do Twojego miejsca zamieszkania. Drzwi otworzą się za około <b>[{secondsUntilPolice < 0 ? "a nie wiem kurwa, co ja jasnowidz jestem?" : secondsUntilPolice}]</b> sekund.
					<br/>
					<br/>
					Jeżeli uda Ci się w tym czasie wypełnić formularz sprawozdawczy, zostaniesz oczyszczony/a z wszelkich podejrzeń.
				</p>
				<button style={{backgroundColor: "#133478", color: "white"}} onClick={() => {
					setStarted(true);
					document.body.requestFullscreen();
				}}>Wypełnij formularz</button>
			</div>
		</>
	);
}

export default App;
